.accordionBase {
  .accordion {
    box-shadow: none;
    margin-top: 0;
    &::before {
      display: none;
    }
  }

  .accordionExpanded {
    margin: 0 !important;
  }

  .accordionSummary {
    padding: 0;
    min-height: auto;
    margin: 0 !important;
  }

  .accordionSummaryExpanded {
    min-height: auto !important;
    margin: 0 !important;
  }

  .accordionSummaryContent {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;

    & .Mui-expanded {
      margin: 0;
    }
  }

  .accordionSummaryExpandIconWrapper {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto !important;
    height: fit-content;
  }

  .accordionDetails {
    width: 100%;
    padding: 0;
  }

  .arrorIcon {
    color: rgba(0, 0, 0, 0.87);
  }
}
