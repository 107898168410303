.rippleLinkListAccordion {
  .list {
    padding: 0 !important;
  }

  .summaryButton {
    height: 44px;
    padding: 8px 8px 8px 16px;
  }

  .detailButton {
    padding-left: 36px !important;
    height: 44px;
  }
}
