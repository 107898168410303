.manager-drawer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .menulist-container {
    width: 320px;
    .category-item {
      height: 44px;
      padding: 8px 8px 8px 16px;
    }
    .menu-item {
      height: 44px;
      padding-left: 36px;
      &.-active {
        background-color: #ebebeb;
      }
    }
  }
}
