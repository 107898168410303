.header-navigation {
  display: flex;
}

.header-navigation h1 {
  width: 130px;
  line-height: 48px;
  margin-left: 20px;
  margin-right: 20px;
}
